<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="40%"
    v-on="$listeners">
    <span slot="title">
      <em
        :style="{color:$store.state.currentTheme.primaryColor}"
        class="iconfont icon-yuandianzhong">
      </em>
      {{ $t('changePsw') }}
    </span>
    <div style="padding: 20px">
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        :label-width="$i18n.locale==='cn'?'80px':'160px'">
        <el-form-item
          :label="$t('email')">
          <el-input type="text" :value="formData.email" disabled></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('newPsw')"
          prop="password">
          <el-input type="password" v-model="formData.password"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('confirmPaw')"
          prop="confirmPaw">
          <el-input type="password" v-model="formData.confirmPaw"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('verificationCode')"
          prop="verifyCode">
          <div style="display: flex">
            <el-input v-model="formData.verifyCode"></el-input>
            <el-button
              style="margin-left: 10px"
              :loading="emailCodeLoading"
              :disabled="emailCodeCountDown>0"
              @click="getCode">
              {{ $t('getCode') }}
              <span v-show="emailCodeCountDown>0">
                {{ `(${emailCodeCountDown})` }}
              </span>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button
      size="small"
      @click="dialogVisible = false">
      {{ $t('cancel') }}
    </el-button>
    <el-button
      size="small"
      type="primary"
      @click="submit">
      {{ $t('submit') }}
    </el-button>
  </span>
  </el-dialog>

</template>

<script>
import baseMixin from '@/mixins/base-mixin'

export default {
  name: 'ChangePasswordDialog',
  mixins: [baseMixin],
  data () {
    const commonVerify = [
      {
        required: true,
        message: this.$t('rejectBeEmpty'),
        trigger: 'blur'
      },
      {
        pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(-)_+=~`]).{8,30})/,
        message: 'Password at least one number, uppercase, lowercase, special characters, 8 to 30 bits in length',
        trigger: 'blur'
      }
    ]
    const validatePsw = (rule, value, callback) => {
      if (value !== this.formData.password) {
        callback(new Error(this.$t('pswNotMatch')))
      } else {
        callback()
      }
    }
    return {
      formData: {
        // originalPsw: '',
        password: '',
        confirmPaw: '',
        email: '',
        verifyCode: '',
        verifyCodeId: ''
      },
      username: localStorage.username,
      rules: {
        password: [...commonVerify],
        confirmPaw: [
          ...commonVerify,
          {
            validator: validatePsw,
            trigger: 'blur'
          }],
        verifyCode: [{
          required: true,
          message: this.$t('rejectBeEmpty'),
          trigger: 'blur'
        }]
      },
      dialogVisible: false
    }
  },
  mounted () {
    this.dialogVisible = this.$attrs.visible
    this.formData.email = this.$store.state.user.email
  },
  methods: {

    getCode () {
      /**
       * 获取验证码
       */
      this.$refs.form.validateField('confirmPaw', err => {
        if (!err) {
          this.getEmailCodeInternal(this.formData.email).then(codeId => {
            this.formData.verifyCodeId = codeId
          })
        }
      })
    },

    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$request.post({
            url: this.$apis.resetPassword,
            data: this.formData
          }).then(data => {
            if (data?.code === 1000) {
              this.$router.push({ name: 'login' })
              this.$message.success(this.$t('pswChangeSuccess'))
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
